/** @format */

import OpenReplay from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import trackerRedux from '@openreplay/tracker-redux';
import trackerGraphQL from '@openreplay/tracker-graphql';

window.__OR__ = {};

const isDevEl = document.getElementById('MMOR-isdev');

const loginDetailsEl = document.getElementById('MMOR-login-details');

const isDev = isDevEl?.dataset?.isdev ? isDevEl.dataset.isdev === 'true' : __DEV__;

const tracker = new OpenReplay({
  projectKey: isDev ? 'imPF4IjNo6jYZiltI3gP' : 'CsxT8RMqdCNFwEzobX54',
  ingestPoint: 'https://openreplay.mightyminds.com.au/ingest',
  defaultInputMode: 0,
  obscureTextNumbers: false,
  obscureTextEmails: false,
  __DISABLE_SECURE_MODE: isDev,
  network: {
    capturePayload: true,
    failuresOnly: false,
    sessionTokenHeader: 'X-OpenReplay-SessionToken',
    ignoreHeaders: false,
    sanitizer: (data) => {
      if (data.request.headers.Cookie) {
        data.request.headers.Cookie = 'COOKIE_SANITISED';
      }

      if (data.request.headers['Set-Cookie']) {
        data.request.headers['Set-Cookie'] = 'SET-COOKIE_SANITISED';
      }
      if (data.request.headers.Authorization) {
        data.request.headers.Authorization = 'AUTHORIZATION_SANITISED';
      }

      return data;
    },
  },
});

if (!__DEV__) {
  // Tracker doesn't work in localhost
  tracker.use(trackerAssist());
}

window.__OR__.reduxMiddleware = tracker.use(trackerRedux());

window.__OR__.recordGraphQL = tracker.use(trackerGraphQL());

window.__OR__.tracker = tracker;

if (loginDetailsEl) {
  const { email, school, role } = loginDetailsEl.dataset;
  tracker.start({
    userID: email,
  });
  tracker.setMetadata('Email', email);
  tracker.setMetadata('School', school);
  tracker.setMetadata('Role', role);
} else {
  tracker.start();
}
